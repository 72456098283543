import Cookies from 'js-cookie'

class FreeWall {
    constructor (theme, elem) {
        this._theme = theme
        this._elem = elem
        this._jilt = window.jilt

        this._settings = {
            defaultScreen: null,
            ...JSON.parse(elem.getAttribute('data-freewall') || '{}')
        }

        this._selectors = {
            panels: '[data-freewall-panel]',

            toggle: '[data-freewall-toggle]',

            overlay: '[data-overlay]',

            submitRegister: '#edd_register_form input[type="submit"]',
            submitLogin: '#edd_login_form input[type="submit"]',
            submitJilt: '#submit-jilt',

            errors: '[data-freewall-errors ]',
            loginErrors: '[data-freewall-login-errors]',
            registerErrors: '[data-freewall-register-errors]'
        }

        this._nodeSelectors = {
            overlay: document.querySelector(this._selectors.overlay),
            toggle: this._elem.querySelectorAll(this._selectors.toggle),
            panels: this._elem.querySelectorAll(this._selectors.panels),
            submitRegister: this._elem.querySelector(this._selectors.submitRegister),
            submitLogin: this._elem.querySelector(this._selectors.submitLogin),
            errors: this._elem.querySelector(this._selectors.errors),
            loginErrors: this._elem.querySelector(this._selectors.loginErrors),
            registerErrors: this._elem.querySelector(this._selectors.registerErrors)
        }
    }

    onInit () {
        if (Cookies.get('lp_freewall') === 'true') return

        this._addEventListeners()
        this._togglePopup()
        this._setDefaultPopup()
    }

    _addEventListeners () {
        this._nodeSelectors.toggle.forEach((el) => {
            el.addEventListener('click', (e) => this._togglePanel(el.getAttribute('data-freewall-toggle')))
        })

        this._nodeSelectors.submitRegister.addEventListener('click', (e) => this._handleRegister(e))

        this._nodeSelectors.submitLogin.addEventListener('click', (e) => this._handleLogin(e))
    }

    _togglePopup (close = false) {
        if(close === true) {
            document.body.classList.remove('prevent-scroll')
            this._elem.classList.add('is-hidden')
            this._nodeSelectors.overlay.classList.add('is-hidden')
        } else {
            document.body.classList.add('prevent-scroll')
            this._elem.classList.remove('is-hidden')
            this._nodeSelectors.overlay.classList.remove('is-hidden')
        }
    }

    _setDefaultPopup () {
        switch (this._settings.defaultScreen) {
            case 'register':
                this._moveErrors('register')
                this._togglePanel('register')
            break

            case 'login':
                this._moveErrors('login')
                this._togglePanel('login')
            break

            default:
                return
            break
        }
    }

    _moveErrors (popup) {
        const errorHtml = this._nodeSelectors.errors.innerHTML

        if (popup === 'login') {
            this._nodeSelectors.loginErrors.innerHTML = errorHtml
        }

        if (popup === 'register') {
            this._nodeSelectors.registerErrors.innerHTML = errorHtml
        }
    }

    _validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    _togglePanel (panelName) {
        this._nodeSelectors.panels.forEach((panel) => {
            if (panel.getAttribute('data-freewall-panel') === panelName) {
                panel.classList.remove('d-none')
            } else {
                panel.classList.add('d-none')
            }
        })
    }

    _handleRegister () {
        window.history.pushState( {}, '', '?popup=register')
    }

    _handleLogin () {       
        window.history.pushState( {}, '', '?popup=login')
    }
}

export default FreeWall