import FreeWall from './components/FreeWall'

const components = {
    'freewall': FreeWall
}

class Theme {
    constructor () {
        this._components = []
        this._componentsById = {}
        this._sections = []
        this._sectionsByType = {}
    }

    registerComponents (container = document) {
        for (let key in components) {
            var ComponentConstructor = components[key]
            var elements = container.querySelectorAll(`.js-component__${key}, [data-${key}]`)
            for (let elem of elements) {
                var instance = new ComponentConstructor(this, elem)
                this._components.push(instance)
                var id = elem.dataset.componentId
                if (id) {
                    this._componentsById[id] = instance
                }
                instance.onInit()
            }
        }
        window.ThemeComponents = this._components
        window.ThemeComponentsId = this._componentsById
        window.__Theme = this
    }

    getComponents () {
        return this._components
    }

    getComponentsById (id) {
        return this._componentsById[id]
    }

    getComponentsByType (componentConst, includeSubclasses = true) {
        var r = []
        for (let c of this._components) {
            if (includeSubclasses && c instanceof componentConst) {
                r.push(c)
            } else if (c.constructor === componentConst) {
                r.push(c)
            }
        }
        return r
    }

    getSections () {
        return this._sections
    }

    getSectionsByType (type) {
        return this._sectionsByType[type]
    }
}

const theme = new Theme()
theme.registerComponents()